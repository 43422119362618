import styled from 'styled-components'
import { PRIMARY_COLOR } from '@/constants/colors'

export const StyledLabel = styled.label`
  font-weight: 500;
  margin: 0 15px 5px 0;
`

export const StyledRadio = styled.input`
    -webkit-appearance: none;
    appearance: none;
    background: #fff; /* this is needed for iOS. Otherwise the background just becomes black. */
    border: 1px solid #333;
    height: 1.5em;
    width: 1.5em;
    display: inline-block;
    border-radius: 100%;
    vertical-align: text-bottom;
    position: relative;

    &[disabled],
    &[aria-disabled='true'] {
      opacity: 0.4;
    }

    &::before {
      content: '';
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      top: 0;
    }

    &:focus {
      outline: 2px solid;
      outline-offset: 2px;
      border: 1px solid ${PRIMARY_COLOR};
    }

    &:checked {
      border: 1px solid ${PRIMARY_COLOR};
    }
  }

  &::before {
    height: 0;
    width: 0;
  }

  &:checked::before {
    border: 5px solid transparent;
    border-radius: 100%;
    outline-offset: -6px;
    background: ${PRIMARY_COLOR};
  }

  &:focus {
    outline-color: #fff;
  }
`
