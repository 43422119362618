import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Card } from '@quotatisgroup/uikit-public.ui.card'
import { Row } from '@quotatisgroup/uikit-public.ui.row'
import { Image } from '@quotatisgroup/uikit-public.ui.image'
import { Title } from '@quotatisgroup/uikit-public.ui.title'
import { Alert } from '@quotatisgroup/uikit-public.ui.alert'
import { Button } from '@quotatisgroup/uikit-public.ui.button'
import { Text } from '@quotatisgroup/uikit-public.ui.text'
import Layout from '@/templates/Layout'

import cloudinary from '@/utils/cloudinary'
import { selectGetOrderId } from '@/store/order/orderSlice'
import { selectUserEmail } from '@/store/auth/authSlice'

function ConfirmationPage() {
  const { t } = useTranslation()
  const orderId = useSelector(selectGetOrderId)
  const email = useSelector(selectUserEmail)
  const alertTitle = t('confirmation.alert.title').toString()

  return (
    <Layout>
      <Row padding="vertical-md">
        <Col md="8" sm="12" offset="2" padding="bottom-md">
          <Card format="simple" margin="sm">
            <Row align="center">
              <Col md="6" offset="3" sm="12" padding="top-md">
                <Image
                  src={cloudinary(
                    '/vitrine/illustration/Formulaire_validé.png',
                  )}
                  size="full"
                  padding="vertical-sm"
                  smPadding="vertical-xs"
                  alt="confirmation illustration"
                />
              </Col>
            </Row>
            <Row align="center">
              <Col md="12">
                <Title headingLevel="3">{t('confirmation.subtitle')}</Title>
              </Col>
            </Row>
            <Row align="center">
              <Col md="10" sm="12" offset="1">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('confirmation.text', { command_id: orderId, email }),
                  }}
                />
              </Col>
            </Row>
            <Row align="center">
              <Col md="10" sm="12" offset="1">
                <Alert
                  align="left"
                  status="info"
                  title={alertTitle}
                  htmlText={
                    t('confirmation.alert.text', {
                      command_id: orderId,
                    }) || ''
                  }
                />
              </Col>
            </Row>
            <Row align="center">
              <Col md="12" margin="top-md">
                <NavLink id="form.link" to="/form">
                  <Button
                    id="confirmation.button.another_command"
                    size="md"
                    variant="basic"
                    md-margin="bottom-sm"
                    margin="right-sm"
                  >
                    {t('confirmation.button.another_command') || ''}
                  </Button>
                </NavLink>
                <NavLink id="form.link" to="/print-confirmation">
                  <Button
                    id="printButton"
                    size="md"
                    md-margin="bottom-sm"
                  >
                    {t('confirmation.printButton') || ''}
                  </Button>
                </NavLink>
              </Col>
            </Row>
            <Row align="center" margin="top-md">
              <Col>
                <Text color="grey" margin="null">
                  {t('confirmation.print.info') || ''}
                </Text>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default ConfirmationPage
