import styled from 'styled-components'
import { Row } from '@quotatisgroup/uikit-public.ui.row'
import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Button } from '@quotatisgroup/uikit-public.ui.button'
import { Card } from '@quotatisgroup/uikit-public.ui.card'
import QRCode from 'react-qr-code'
import { GREY_COLOR, GREY_COLOR_LIGHT, PRIMARY_COLOR } from '@/constants/colors'

export const StyledRow = styled(Row)`
  @media print {
    margin-top: none;
  }
`

export const StyledHr = styled.hr`
  margin-top: 24px;
  border: 0;
  border-top: 0.5px solid ${GREY_COLOR_LIGHT};
`

export const StyledButtonRow = styled(Row)`
  @media print {
    display: none;
  }
`

export const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
`

export const StyledButton = styled(Button)`
  .icon-link {
    margin: 0;
    position: relative;
  }
`

export const StyledCard = styled(Card)`
  @media print {
    padding: 0;
    margin: 0;
    box-shadow: none;
  }
`

export const StyledQRCode = styled(QRCode)`
  border: 0.5px solid ${GREY_COLOR_LIGHT};
  border-radius: 10px;
  padding: 14px;
`

export const StyledPrintP = styled.p`
  @media print {
    font-size: 12px;
  }
`

export const StyledTextHelp = styled(StyledPrintP)`
  color: ${GREY_COLOR};
  span {
    color: ${PRIMARY_COLOR};
  }
`

export const StyledText = styled(StyledPrintP)`
  margin: 0;
`

export const StyledP = styled(StyledTextHelp)`
  margin-top: 0;
`

export const StyledFirstDiv = styled.div`
  border-radius: 100px;
  width: 30px;
  height: 30px;
  background-color: ${PRIMARY_COLOR};
  color: white;
  font-weight: 600;
  text-align: center;
  span {
    line-height: 2;
  }
`

export const StyledDiv = styled.div`
  border-radius: 100px;
  width: 30px;
  height: 30px;
  background-color: ${GREY_COLOR_LIGHT};
  color: ${GREY_COLOR};
  font-weight: 600;
  text-align: center;
  span {
    line-height: 2;
  }
`

export const StyledStepsCol = styled(Col)`
  align-items: center;
  display: flex;
`

export const StyledStepOneText = styled(StyledPrintP)`
  span {
    font-size: 12px;
  }
`
