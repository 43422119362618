import React from 'react'
import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Image } from '@quotatisgroup/uikit-public.ui.image'
import { Row } from '@quotatisgroup/uikit-public.ui.row'
import { Footer as FooterUikit } from '@quotatisgroup/uikit-public.ui.footer'

function Footer() {
  return <FooterUikit>
    <Row hasVerticalAlign margin="top-md">
      <Col md="12" align="center">
        <Image
          src="https://res.cloudinary.com/quotatis/image/upload/v1671034726/FrontApp/vitrine/logo/quotatis-footer-logo.png"
          size="md"
          padding="vertical-sm"
          alt="logo Quotatis"
        />
      </Col>
    </Row>
  </FooterUikit>
}

export default Footer
