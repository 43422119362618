import React, { ReactNode, useEffect } from 'react'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import { useAppDispatch } from '@/store'

import Header from '@/components/Header/Header'
import Footer from '@/components/Footer'
import { BACKGROUND_COLOR } from '@/constants/colors'
import { checkSession, ACCESS_TOKEN, ID_TOKEN } from '@/store/auth/authSlice'

type PageTemplateProps = {
  children: ReactNode
}

const Container = styled.div`
  min-height: calc(100vh - 78px);
`

const Background = styled.div`
  background-color: ${BACKGROUND_COLOR};
  min-height: calc(100vh - 78px);
`

const StyledContainer = styled.div`
  @media print {
    display: none;
    height: 0;
    line-height: 0;
  }
`

function PageTemplate({ children }: PageTemplateProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const hash = location?.hash.split('&')

  useEffect(() => {
    const accesToken =
      hash?.[0]?.replace(`#${ACCESS_TOKEN}=`, '') ||
      localStorage.getItem(ACCESS_TOKEN)
    const idToken =
      hash?.[5]?.replace(`${ID_TOKEN}=`, '') || localStorage.getItem(ID_TOKEN)

    if (accesToken && idToken) {
      dispatch(checkSession()).catch(error => console.error(error))
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('homepage.meta.title')}</title>
      </Helmet>
      <Background>
        <Container>
          <StyledContainer>
            <Header />
          </StyledContainer>
          <ToastContainer
            className="toast-container"
            position="top-right"
            transition={Slide}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {children}
        </Container>
        <StyledContainer>
          <Footer />
        </StyledContainer>
      </Background>
    </>
  )
}

export default PageTemplate
