import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import format from 'date-fns/format'

import { Row } from '@quotatisgroup/uikit-public.ui.row'
import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Button } from '@quotatisgroup/uikit-public.ui.button'
import { Image } from '@quotatisgroup/uikit-public.ui.image'
import { Icon } from '@quotatisgroup/uikit-public.ui.icon'
import { Text } from '@quotatisgroup/uikit-public.ui.text'
import { Title } from '@quotatisgroup/uikit-public.ui.title'
import Layout from '@/templates/Layout'

import config from '@/config'
import cloudinary from '@/utils/cloudinary'
import { getUTCDate } from '@/utils/validation'
import {
  selectGetOrderId,
  currentOrderPackageName,
  getCurrentOrderProductQtt,
  getCurrentOrderPurchaseDate,
  getCurrentOrderWorksiteDeliveryDate,
  getCurrentOrderClientPhoneNumber,
  getCurrentOrderClientEmail,
} from '@/store/order/orderSlice'
import DATE_FORMAT from '@/constants/dateFormats'

import {
  StyledRow,
  StyledHr,
  StyledButton,
  StyledButtonRow,
  StyledCol,
  StyledCard,
  StyledQRCode,
  StyledTextHelp,
  StyledPrintP,
  StyledText,
  StyledP,
  StyledFirstDiv,
  StyledDiv,
  StyledStepsCol,
  StyledStepOneText,
} from './PrintPageStyle'

function PrintPage() {
  const { t } = useTranslation()
  const packageName = useSelector(currentOrderPackageName)
  const packageQtt = useSelector(getCurrentOrderProductQtt)
  const purchaseDate = useSelector(getCurrentOrderPurchaseDate)
  const worksiteDeliveyDate = useSelector(getCurrentOrderWorksiteDeliveryDate)
  const clientEmail = useSelector(getCurrentOrderClientEmail)
  const clientPhoneNumber = useSelector(getCurrentOrderClientPhoneNumber)
  const orderId = useSelector(selectGetOrderId)

  const prettyPurchaseDate = purchaseDate
    ? format(getUTCDate(purchaseDate), DATE_FORMAT.FR_LONG)
    : ''

  const prettyWorksiteDeliveyDate = worksiteDeliveyDate
    ? format(getUTCDate(worksiteDeliveyDate), DATE_FORMAT.FR_LONG)
    : null

  const prettyClientPhoneNumber =
    clientPhoneNumber && clientPhoneNumber.substring(3)

  const prettySdpUrl = `${config.sdpUrl}/suivi/${orderId || ''}`

  return (
    <Layout>
      <StyledButtonRow padding="top-sm">
        <Col md="8" sm="12" offset="2">
          <NavLink id="form.link" to="/confirmation-form">
            <StyledButton
              id="back-to-confirmation"
              size="xs"
              variant="primary"
              hasBorder
              margin="left-sm"
              icon="arrow-ios-left"
              iconPosition="left"
            >
              {t('printPage.backButton') || ''}
            </StyledButton>
          </NavLink>
          <Button
            id="imprimer"
            size="xs"
            variant="primary"
            md-margin="bottom-sm"
            margin="right-sm"
            align="right"
            onClick={() => window.print()}
            icon="printer"
            iconPosition="left"
          >
            {t('printPage.printButton') || ''}
          </Button>
        </Col>
      </StyledButtonRow>
      <StyledRow padding="vertical-md">
        <Col md="8" sm="12" offset="2" padding="bottom-md">
          <StyledCard format="simple" margin="sm" padding="md">
            <Row>
              <Col md="9" sm="9" mdAlign="left" padding="vertical-sm">
                <Row>
                  <Col md="1">
                    <Icon name="checkmark-circle" color="success" size="xl" />
                  </Col>
                  <StyledCol md="11">
                    <Title headingLevel="3" margin="left-sm">
                      {t('PrintPage.title') || ''}
                    </Title>
                  </StyledCol>
                </Row>
              </Col>
              <Col md="3" sm="3" align="right">
                <Image
                  src={cloudinary(
                    '/vitrine/logo/quotatis-footer-tracking-primary-fr.png',
                  )}
                  size="md"
                  alt="quotatis with love illustration"
                />
              </Col>
            </Row>
            <StyledHr />
            <Row>
              <Col>
                <Title headingLevel="5">
                  {t('PrintPage.summary.title') || ''}
                </Title>
              </Col>
              <Col md="12">
                <Row>
                  <Col md="7" sm="7">
                    <Text margin="null">
                      {t('PrintPage.summary.packageName.label') || ''}
                    </Text>
                    <Text margin="null">
                      {t('PrintPage.summary.packageQtt.label') || ''}
                    </Text>
                    <Text margin="null">
                      {t('PrintPage.summary.purchaseDate.label') || ''}
                    </Text>
                    {prettyWorksiteDeliveyDate && (
                      <Text margin="null">
                        {t('PrintPage.summary.worksiteDeliveyDate.label') || ''}
                      </Text>
                    )}
                    <Text margin="null">
                      {t('PrintPage.summary.clientEmail.label') || ''}
                    </Text>
                    <Text margin="null">
                      {t('PrintPage.summary.clientPhoneNumber.label') || ''}
                    </Text>
                    <Text margin="null">
                      {t('PrintPage.summary.orderId.label') || ''}
                    </Text>
                  </Col>
                  <Col md="5" sm="5" align="right">
                    <Text margin="null" bold>
                      {t('PrintPage.summary.packageName.value', {
                        packageName,
                      }) || ''}
                    </Text>
                    <Text margin="null" bold>
                      {t('PrintPage.summary.packageQtt.value', {
                        packageQtt,
                      }) || ''}
                    </Text>
                    <Text margin="null" bold>
                      {t('PrintPage.summary.purchaseDate.value', {
                        purchaseDate: prettyPurchaseDate,
                      }) || ''}
                    </Text>
                    {prettyWorksiteDeliveyDate && (
                      <Text margin="null" bold>
                        {t('PrintPage.summary.worksiteDeliveyDate.value', {
                          worksiteDeliveyDate: prettyWorksiteDeliveyDate,
                        }) || ''}
                      </Text>
                    )}
                    <Text margin="null" bold>
                      {t('PrintPage.summary.clientEmail.value', {
                        clientEmail,
                      }) || ''}
                    </Text>
                    <Text margin="null" bold>
                      {t('PrintPage.summary.clientPhoneNumber.value', {
                        clientPhoneNumber: prettyClientPhoneNumber,
                      }) || ''}
                    </Text>
                    <Text margin="null" bold>
                      {t('PrintPage.summary.orderId.value', { orderId }) || ''}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <StyledHr />
            <Row>
              <Col md="2" sm="4" smAlign="center" padding="vertical-sm">
                <StyledQRCode
                  size={256}
                  style={{ height: 'auto', maxWidth: '50%', width: '50%' }}
                  value={prettySdpUrl}
                  viewBox="0 0 256 256"
                />
              </Col>
              <Col md="10" sm="8">
                <Title margin="top-sm" headingLevel="3">
                  {t('PrintPage.CodeQR.subtitle') || ''}
                </Title>
                <StyledText>
                  {t('PrintPage.CodeQR.description') || ''}
                </StyledText>
                <StyledP
                  dangerouslySetInnerHTML={{
                    __html: t('PrintPage.CodeQR.acces', {
                      sdpUrl: prettySdpUrl,
                    }),
                  }}
                />
              </Col>
            </Row>
            <StyledHr />
            <Title headingLevel="5">
              {t('PrintPage.steps.subtitle') || ''}
            </Title>
            <Row>
              <StyledStepsCol md="1" sm="1">
                <StyledFirstDiv>
                  <span>1</span>
                </StyledFirstDiv>
              </StyledStepsCol>
              <Col md="11" sm="11">
                <StyledStepOneText
                  dangerouslySetInnerHTML={{
                    __html: t('PrintPage.step1'),
                  }}
                />
              </Col>
            </Row>
            <Row>
              <StyledStepsCol md="1" sm="1">
                <StyledDiv>
                  <span>2</span>
                </StyledDiv>
              </StyledStepsCol>
              <Col md="11" sm="11">
                <StyledPrintP
                  dangerouslySetInnerHTML={{
                    __html: t('PrintPage.step2'),
                  }}
                />
              </Col>
            </Row>
            <Row>
              <StyledStepsCol md="1" sm="1">
                <StyledDiv>
                  <span>3</span>
                </StyledDiv>
              </StyledStepsCol>
              <Col md="11" sm="11">
                <StyledPrintP
                  dangerouslySetInnerHTML={{
                    __html: t('PrintPage.step3'),
                  }}
                />
              </Col>
            </Row>
            <Row>
              <StyledStepsCol md="1" sm="1">
                <StyledDiv>
                  <span>4</span>
                </StyledDiv>
              </StyledStepsCol>
              <Col md="11" sm="11">
                <StyledPrintP
                  dangerouslySetInnerHTML={{
                    __html: t('PrintPage.step4'),
                  }}
                />
              </Col>
            </Row>
            <StyledHr />
            <Row align="center">
              <Col>
                <StyledTextHelp
                  dangerouslySetInnerHTML={{
                    __html: t('PrintPage.helpContact', { orderId }),
                  }}
                />
              </Col>
            </Row>
          </StyledCard>
        </Col>
      </StyledRow>
    </Layout>
  )
}

export default PrintPage
