import config from '@/config'

type CloudinaryType = {
  cloudinary: { app: string; url: string }
}

export default (path: string, params?: string) => {
  const { cloudinary }: CloudinaryType = config
  if (!params) {
    const isSvg = path.endsWith('.svg')
    const qualitySettings = isSvg ? '' : '/f_auto,q_auto'
    const cloudinaryUrl = cloudinary.url || ''
    const cloudinaryAppName = cloudinary.app || ''
    const url = `${cloudinaryUrl}${qualitySettings}/${cloudinaryAppName}${path}`

    return url
  }
  const cloudinaryParts = path.split('/')
  const cloudinaryParams = cloudinaryParts[6]
  const arr = path.split(cloudinaryParams)
  return `${arr[0]}${params}${arr[1]}`
}
