import styled from 'styled-components'
import { Row } from '@quotatisgroup/uikit-public.ui.row'
import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Text } from '@quotatisgroup/uikit-public.ui.text'
import { Link } from '@quotatisgroup/uikit-public.ui.link'
import { GREY_COLOR_LIGHT } from '@/constants/colors'

export const Shadow = styled(Row)`
  margin: 0 0 3px;
  background: white;
  box-shadow: 0px 0px 10px #dfdfdf;
`

export const StyledRow = styled(Row)`
  float: right;
`

export const StyledAccountWrapper = styled.div`
  width: 100%;
`

export const StyledCol = styled(Col)`
  position: absolute;
  top: 60px;
`
export const StyledDesktopAccount = styled(Col)`
  position: relative;
  min-width: 200px;
  max-width: 400px;
  float: right;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 0 2px 0 rgba(143, 155, 179, 0.04),
    0 2px 6px 0 rgba(143, 155, 179, 0.16);
  z-index: 2;
  padding: 14px;
`

export const StyledPhoneAccount = styled(StyledDesktopAccount)`
  position: absolute;
  top: 60px;
  right: 16px;
`

export const StyledText = styled(Text)`
  font-weight: 500;
`

export const StyledHr = styled.hr`
  margin-top: 0;
  border: 0;
  border-top: 0.5px solid ${GREY_COLOR_LIGHT};
`

export const StyledLink = styled(Link)`
  font-weight: 600;
  &:hover {
    text-decoration: none;
  }
`

export const Clickable = styled.div`
  cursor: pointer;
`
