import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Image } from '@quotatisgroup/uikit-public.ui.image'
import { Title } from '@quotatisgroup/uikit-public.ui.title'
import { Icon } from '@quotatisgroup/uikit-public.ui.icon'

import { selectUserEmail } from '@/store/auth/authSlice'
import cloudinary from '@/utils/cloudinary'
import MenuAccount from './MenuAccount'
import {
  Shadow,
  StyledRow,
  StyledAccountWrapper,
  Clickable,
} from './HeaderStyle'

function Header() {
  const { t } = useTranslation()
  const email = useSelector(selectUserEmail)

  const openInnerRef = useRef<HTMLDivElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const openMenu = () => setIsMenuOpen(true)

  useEffect(() => {
    if (openInnerRef.current) {
      const handleClickOutside = (event: Event) => {
        if (!openInnerRef.current?.contains(event.target as Node)) {
          setIsMenuOpen(false)
        } else {
          setIsMenuOpen(true)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        window.removeEventListener('mousedown', handleClickOutside)
      }
    }
    return () => {}
  }, [openInnerRef, setIsMenuOpen])

  return (
    <Shadow hasVerticalAlign>
      <Col md="2" sm="2" align="left" mdPadding="left-xs" offset="2">
        <NavLink id="home.link" to="/">
          <Col sm="hide">
            <Image
              src={cloudinary('/vitrine/logo/logo_baseline.svg')}
              size="md"
              padding="vertical-sm"
              smPadding="vertical-xs"
              alt="logo Quotatis"
            />
          </Col>
          <Col md="hide">
            <Image
              src={cloudinary('/vitrine/logo/quotatis_small.png')}
              size="sm"
              padding="vertical-sm"
              smPadding="vertical-xs"
              alt="logo Quotatis"
            />
          </Col>
        </NavLink>
      </Col>
      <Col md="4" sm="8" align="center">
        <Title headingLevel="4">{t('homePage.header.title')}</Title>
      </Col>

      {email && (
        <Col md="2" sm="2">
          <Clickable
            role="link"
            aria-hidden="true"
            id="connection.header.link"
            onClick={openMenu}
          >
            <StyledRow sm="hide" mdPadding="right-sm">
              <Icon
                name="person"
                size="md"
                color="primary"
                mdMargin="right-xs"
              />
              <Title headingLevel="4" color="primary">
                {t('connection.header.logo')}
              </Title>
            </StyledRow>
            <Icon
              name="person"
              size="md"
              color="primary"
              align="right"
              smMargin="right-sm"
              md="hide"
            />
          </Clickable>
        </Col>
      )}
      <StyledAccountWrapper ref={openInnerRef}>
        <MenuAccount isMenuOpen={isMenuOpen} />
      </StyledAccountWrapper>
    </Shadow>
  )
}

export default Header
