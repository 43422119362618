/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import jwt_decode from 'jwt-decode'
import i18n from '@/i18n/hoc/i18n'

import auth0Partner from '@/services/auth'
import { getSignInErrors, SignInErrors } from '@/services/auth/error'
import type { RootState } from '@/store'

export enum PasswordlessStatus {
  NOT_CONNECTED = 'not_connected',
  EMAIL_SENT = 'email_sent',
  FAILED = 'failed',
  LOADING = 'loading',
}

export const ACCESS_TOKEN = 'access_token'
export const ID_TOKEN = 'id_token'

type AuthState = {
  user: {
    email: string | null
  }
  emailSent: string | null
  emailStatus: PasswordlessStatus
  accessToken: string
  idToken: string
}

export const passwordlessStart = createAsyncThunk(
  'auth/login',
  async (data: { email: string }) => {
    const response = await auth0Partner.passwordlessStart(data)

    return response
  },
)

export const checkSession = createAsyncThunk('auth/checkSession', async () => {
  const response = await auth0Partner.checkSession()

  return response
})

export const logout = createAsyncThunk('auth/logout', () => {
  auth0Partner.logout()
})

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: {
      email: null,
    },
    emailSent: null,
    emailStatus: PasswordlessStatus.NOT_CONNECTED,
    accessToken: localStorage.getItem(ACCESS_TOKEN) || '',
    idToken: localStorage.getItem(ID_TOKEN) || '',
  } as AuthState,
  reducers: {
    resetAuthForm: state => {
      state.emailSent = null
      state.emailStatus = PasswordlessStatus.NOT_CONNECTED
    },
  },
  extraReducers: builder => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(passwordlessStart.fulfilled, (state, action) => {
      const payload = action?.payload as {
        code: SignInErrors
      }
      const code = payload?.code
      state.emailSent = !code ? action.meta.arg.email : null
      state.emailStatus = !code
        ? PasswordlessStatus.EMAIL_SENT
        : PasswordlessStatus.FAILED
      if (code) {
        toast.error(i18n.t(getSignInErrors(code)))
      }
    })
    builder.addCase(passwordlessStart.pending, state => {
      state.emailStatus = PasswordlessStatus.LOADING
    })
    builder.addCase(passwordlessStart.rejected, state => {
      state.emailStatus = PasswordlessStatus.FAILED
    })
    builder.addCase(checkSession.fulfilled, (state, action) => {
      const payload = action?.payload as {
        code: SignInErrors
        accessToken: string
        idToken: string
      }
      const result = !payload.code
      if (!result) {
        state.accessToken = ''
        state.idToken = ''
        localStorage.removeItem(ACCESS_TOKEN)
        localStorage.removeItem(ID_TOKEN)
        toast.error(i18n.t(getSignInErrors(payload.code)))

        return
      }
      const { accessToken } = payload
      const { idToken } = payload
      const jwtDecoded: { email: string } = jwt_decode(idToken)

      localStorage.setItem(ACCESS_TOKEN, accessToken)
      localStorage.setItem(ID_TOKEN, idToken)

      state.accessToken = accessToken
      state.idToken = idToken
      state.user.email = jwtDecoded.email
    })
    builder.addCase(logout.fulfilled, state => {
      state.idToken = ''
      state.accessToken = ''
      localStorage.removeItem(ACCESS_TOKEN)
      localStorage.removeItem(ID_TOKEN)
    })
  },
})

export const { resetAuthForm } = slice.actions

export default slice.reducer

export const selectEmailStatus = (state: RootState) => state.auth.emailStatus
export const selectEmailSent = (state: RootState) => state.auth.emailSent
export const selectIdToken = (state: RootState) =>
  state.auth.idToken || localStorage.getItem(ID_TOKEN)
export const selectAccessToken = (state: RootState) =>
  state.auth.accessToken || localStorage.getItem(ACCESS_TOKEN)
export const selectIsAuthenticated = (state: RootState) =>
  state?.auth?.accessToken?.length > 0 && state?.auth?.idToken?.length > 0
export const selectUserEmail = (state: RootState) => state.auth.user.email
