import styled from 'styled-components'
import { Phone } from '@quotatisgroup/uikit-public.ui.phone'
import { TextField } from '@quotatisgroup/uikit-public.ui.text-field'
import { Tooltip } from '@quotatisgroup/uikit-public.ui.tooltip'
import { GREY_COLOR } from '@/constants/colors'

export const LabelWithIcon = styled.label`
  display: flex;
  align-items: center;
  color: ${GREY_COLOR};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
`

export const StyledPhone = styled(Phone)`
  margin-top: 0;
`

export const StyledTextField = styled(TextField)`
  margin-top: 0;
`

export const StyledTooltip = styled(Tooltip)`
  left: 20px;
`
