import React from 'react'
import { StyledLabel, StyledRadio } from './RadioStyle'

type RadioProps = {
  value: string
  id: string
  label: string
  name: string
  onBlur: () => void
  onChange: () => void
  defaultChecked?: boolean
} & typeof defaultProps

export const defaultProps = {
  defaultChecked: false,
}

function Radio({
  value,
  id,
  label,
  defaultChecked,
  name,
  onBlur,
  onChange,
}: RadioProps) {
  return (
    <StyledLabel htmlFor={id}>
      <StyledRadio
        type="radio"
        value={value}
        id={id}
        checked={defaultChecked}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
      />
      {label}
    </StyledLabel>
  )
}

Radio.defaultProps = defaultProps

export default Radio
