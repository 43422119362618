import { useDispatch } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import authReducer from './auth/authSlice'
import orderReducer from './order/orderSlice'

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

export const store = configureStore({
  reducer: {
    auth: authReducer,
    order: orderReducer,
  },
  enhancers: [sentryReduxEnhancer],
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch

export default store
