export const signUpErrors = {
  invalid_password: {
    propertyPath: 'password',
    message: 'validators.user.password.invalid_password',
  },
  password_dictionary_error: {
    propertyPath: 'password',
    message: 'validators.password.dictionary_error',
  },
  password_strength_error: {
    propertyPath: 'password',
    message: 'validators.password.strength_error',
  },
  user_exists: {
    propertyPath: 'email',
    message: 'validators.user.already_exist',
  },
}

export type SignInErrors =
  | 'invalid_grant'
  | 'too_many_attempts'
  | 'unauthorized'
  | 'unauthorized_firm_user'
  | 'bad.connection'
  | 'default'
  | 'login_required'

export const signInErrors = {
  invalid_grant: {
    error_description: 'Invalid username and password combination',
  },
  too_many_attempts: {
    error_description: 'user.sign_in.blocked_many_attempts',
  },
  unauthorized: {
    error_description: 'user.sign_in.unauthorized',
  },
  unauthorized_firm_user: {
    error_description: 'user.sign_in.error_firm_user',
  },
  'bad.connection': {
    error_description: 'login-form.error.default',
  },
  default: {
    error_description: 'login-form.error.default',
  },
  login_required: {
    error_description: 'session.error',
  },
}

export const getSignInErrors = (key: SignInErrors): string =>
  signInErrors?.[key]?.error_description ||
  signInErrors?.default?.error_description
