import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Card } from '@quotatisgroup/uikit-public.ui.card'
import { Image } from '@quotatisgroup/uikit-public.ui.image'
import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Row } from '@quotatisgroup/uikit-public.ui.row'
import { Button } from '@quotatisgroup/uikit-public.ui.button'
import { Title } from '@quotatisgroup/uikit-public.ui.title'
import { Text } from '@quotatisgroup/uikit-public.ui.text'
import { TextField } from '@quotatisgroup/uikit-public.ui.text-field'
import cloudinary from '@/utils/cloudinary'
import Layout from '@/templates/Layout'
import { useAppDispatch } from '@/store'
import {
  passwordlessStart,
  resetAuthForm,
  selectEmailStatus,
  selectEmailSent,
  selectIsAuthenticated,
  PasswordlessStatus,
} from '@/store/auth/authSlice'
import { isValidEmail } from '@/utils/validation'

type FormData = {
  email: string
}

function LoginPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const emailStatus = useSelector(selectEmailStatus)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isFormLoading = emailStatus === PasswordlessStatus.LOADING
  const emailSent = useSelector(selectEmailSent)
  const { t } = useTranslation()
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormData>()
  const isEmailSent = emailStatus === PasswordlessStatus.EMAIL_SENT
  const emailInput = useWatch({ control, name: 'email' })

  const onSubmit: SubmitHandler<FormData> = data => {
    dispatch(passwordlessStart(data)).catch(error => console.error(error))
  }

  const resetForm = () => {
    dispatch(resetAuthForm())
  }

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
  }, [isAuthenticated, navigate])

  return (
    <Layout>
      <Row padding="top-md">
        <Col md="8" sm="12" offset="2" padding="bottom-md">
          <Card format="simple" margin="sm">
            <Row align="center">
              <Col md="6" offset="3" sm="12" padding="top-md">
                <Image
                  src={cloudinary(
                    `/vitrine/illustration/${
                      isEmailSent ? 'partner_email_sent' : 'partner_login'
                    }`,
                  )}
                  size="xl"
                  padding="vertical-sm"
                  smPadding="vertical-xs"
                  alt="home illustration"
                />
              </Col>
            </Row>
            <Row align="center">
              <Col md="12">
                <Title headingLevel="3">
                  {t(
                    `${
                      isEmailSent
                        ? 'login-form.email-sent.title'
                        : 'login-form.title'
                    }`,
                  )}
                </Title>
              </Col>
            </Row>
            <Row align="center">
              <Col md="12" sm="12">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      isEmailSent
                        ? 'login-form.email-sent.description'
                        : 'login-form.description',
                      { email: emailSent },
                    ),
                  }}
                />
              </Col>
            </Row>
            <Row align="center">
              <Col
                sm="12"
                md={!isEmailSent ? '8' : '12'}
                offset={!isEmailSent ? '2' : undefined}
              >
                {!isEmailSent ? (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                      name="email"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: 'field.required',
                      }}
                      render={({
                        field: { name, value, onBlur, onChange },
                      }) => (
                        <TextField
                          name={name}
                          value={value}
                          onBlur={onBlur}
                          onChange={onChange}
                          disabled={isFormLoading}
                          type="text"
                          placeholder={t('field.email.placeholder') || ''}
                          errorMessage={
                            !isValidEmail(emailInput) && emailInput
                              ? t('field.email.error.format') || undefined
                              : undefined
                          }
                        />
                      )}
                    />
                    <Button
                      id="login.button"
                      type="submit"
                      size="md"
                      margin="vertical-sm"
                      isLoading={isFormLoading}
                      disabled={
                        !!errors.email ||
                        !emailInput ||
                        !isValidEmail(emailInput)
                      }
                    >
                      <span>{t('login-form.button.text')}</span>
                    </Button>
                  </form>
                ) : (
                  <>
                    <Row>
                      <Col md="12">
                        <Text>
                          {t('login-form.success.resent.button.title')}
                        </Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Button
                          id="resent.button"
                          size="sm"
                          hasLinkStyle
                          color="primary"
                          margin="null"
                          onClick={resetForm}
                        >
                          <Text>
                            {t('login-form.success.resent.button.text')}
                          </Text>
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default LoginPage
