import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'

import './index.css'
import HomePage from '@/pages/HomePage/HomePage'
import LoginPage from '@/pages/LoginPage/LoginPage'
import OrderFormPage from '@/pages/OrderFormPage'
import ConfirmationPage from '@/pages/ConfirmationPage/ConfirmationPage'
import PrintPage from '@/pages/ConfirmationPage/PrintPage'
import ErrorPage from '@/pages/ErrorPage/ErrorPage'
import NotFoundPage from '@/pages/NotFoundPage/NotFoundPage'
import RequireAuth from '@/hoc/security/RequireAuth'
import AxiosProvider from '@/hoc/api/AxiosProvider'
import reportWebVitals from './reportWebVitals'
import store from '@/store'
import config from '@/config'
import './i18n/hoc/i18n'

Sentry.init({
  dsn: config.sentry.url,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: config.env,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: config.sentry.tracesSampleRate,
  replaysSessionSampleRate: config.sentry.replaysSessionSampleRate,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 10,
})

const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/',
    element: (
      <RequireAuth>
        <HomePage />
      </RequireAuth>
    ),
  },
  {
    path: '/form',
    element: (
      <RequireAuth>
        <OrderFormPage />
      </RequireAuth>
    ),
  },
  {
    path: '/confirmation-form',
    element: (
      <RequireAuth>
        <ConfirmationPage />
      </RequireAuth>
    ),
  },
  {
    path: '/print-confirmation',
    element: (
      <RequireAuth>
        <PrintPage />
      </RequireAuth>
    ),
  },
  {
    path: '/error-form',
    element: (
      <RequireAuth>
        <ErrorPage />
      </RequireAuth>
    ),
  },
  {
    path: '*',
    element: (
      <RequireAuth>
        <NotFoundPage />
      </RequireAuth>
    ),
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <HelmetProvider>
        <AxiosProvider>
          <RouterProvider router={router} />
        </AxiosProvider>
      </HelmetProvider>
    </React.StrictMode>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
