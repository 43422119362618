import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'

import { Card } from '@quotatisgroup/uikit-public.ui.card'
import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Row } from '@quotatisgroup/uikit-public.ui.row'
import { Text } from '@quotatisgroup/uikit-public.ui.text'
import { Alert } from '@quotatisgroup/uikit-public.ui.alert'
import { Checkbox } from '@quotatisgroup/uikit-public.ui.checkbox'
import { Title } from '@quotatisgroup/uikit-public.ui.title'
import { Icon } from '@quotatisgroup/uikit-public.ui.icon'
import { TextField } from '@quotatisgroup/uikit-public.ui.text-field'

import {
  setFormError,
  getFeatureFlags,
  fetchPackages,
  ORDER_STEP1,
  selectPackageList,
} from '@/store/order/orderSlice'
import { numberVerification } from '@/utils/validation'
import { DEFAULT_VALUE_ONE } from '@/constants/form'
import Product from './Product'
import FormData from './FormPartPackageTypes'
import {
  StyledHr,
  StyledLink,
  StyledSelect,
  StyledTextLabel,
  Label,
  StyledDatePicker,
} from './style'
import useMount from '@/hooks/useMount'
import { useAppDispatch } from '@/store'
import PackageType from '@/form/FormPartPackage/PackageType'

function FormPartPackage() {
  const { t } = useTranslation()
  const [displayedProducts, setDisplayedProducts] = useState(true)
  const [productsIds, setProductsIds] = useState([0])
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [optionsUpdated, setOptionsUpdated] = useState(false)
  const dispatch = useAppDispatch()
  const orderFormStep1ValuesStringified = localStorage.getItem(ORDER_STEP1)
  const packageList = useSelector(selectPackageList)

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      productWorksiteDeliveryDate: new Date().toISOString(),
    },
  })

  const onSubmit: SubmitHandler<FormData> = data => {
    localStorage.setItem(ORDER_STEP1, JSON.stringify(data))
  }
  const hasFormError = (Object.keys(errors) || []).length > 0

  const submitTrigger = useCallback(() => {
    setHasSubmitted(true)
    handleSubmit(onSubmit)().catch(error => console.error(error))
  }, [handleSubmit])

  // Local Storage
  useEffect(() => {
    if (!orderFormStep1ValuesStringified || hasSubmitted) {
      return
    }

    // Get localStorage info
    const orderFormInfoStep1 = JSON.parse(orderFormStep1ValuesStringified || '')

    // Open products form fields
    setProductsIds(orderFormInfoStep1.productLabel)

    type KeysType = keyof FormData

    // Set localStorage info on fields
    const keys = Object.keys(orderFormInfoStep1) as Array<KeysType>
    keys.map((key: KeysType) => setValue(key, orderFormInfoStep1[key]))

    dispatch(setFormError(false))
  }, [
    setValue,
    submitTrigger,
    orderFormStep1ValuesStringified,
    hasSubmitted,
    dispatch,
  ])

  useEffect(() => {
    const subscription = watch(value => {
      const isWholePositiveInteger = numberVerification(value.quantity)
      const requireProducts =
        packageList.find(option => option.code === value.code)
          ?.requireProducts || false

      if (requireProducts && !displayedProducts) {
        setDisplayedProducts(true)
      }

      const isValidProductLabel =
        Array.isArray(value?.productLabel) &&
        value?.productLabel.length > 0 &&
        value?.productLabel.every(
          label => label?.trim() !== '' && label?.length && label?.length > 1,
        )
      const isValidProductReference =
        Array.isArray(value?.productReference) &&
        !value?.productReference.includes('') &&
        !value?.productReference.includes('0') &&
        value?.productReference.length > 0 &&
        value?.productReference.every(
          label => label?.length && label?.length > 1,
        )

      const hasPackages =
        value.code && value['package-checked'] && isWholePositiveInteger

      if (
        (hasPackages && !requireProducts) ||
        (hasPackages &&
          isValidProductLabel &&
          isValidProductReference &&
          value.productWorksiteDeliveryDate &&
          value?.productWorksiteDeliveryDate !== '' &&
          value?.productWorksiteDeliveryDate !== undefined)
      ) {
        dispatch(setFormError(false))
        submitTrigger()
      } else {
        dispatch(setFormError(true))
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, submitTrigger, hasFormError, dispatch, packageList])

  const noticeErrorMessage: string = errors?.code?.message || ''
  const quantityErrorMessage: string = errors?.quantity?.message || ''

  useMount(() => {
    dispatch(setFormError(true))
    dispatch(getFeatureFlags()).catch(error => console.error(error))
    dispatch(fetchPackages()).catch(error => console.error(error))
  })

  const optionsFormated = [...packageList]
    .sort((a: PackageType, b: PackageType) => (a.name < b.name ? -1 : 1))
    .map((choice: PackageType) => ({
      label: `${choice.name}`,
      value: choice.code,
      requireProducts: choice.requireProducts || false,
      selected:
        JSON.parse(localStorage.getItem(ORDER_STEP1) || '{}')?.code ===
        choice.code,
    }))

  useEffect(() => {
    if (optionsFormated.length > 0) {
      setOptionsUpdated(true)
    }
  }, [optionsFormated])

  return (
    <form>
      <Card format="simple" margin="vertical-sm">
        <Row>
          <Col>
            <Title headingLevel="3">{t('order-form-step1.titlePackage')}</Title>
          </Col>
        </Row>
        <Row>
          <Col sm="12" smMargin="bottom-xs">
            {t('order-form-step1.descriptionPackage')}
          </Col>
        </Row>
        <Row margin="bottom-xs">
          <Col sm="12">
            <StyledTextLabel caption color="grey" margin="top-sm">
              {t('order-form-step1.labelPackageType') || ''}
            </StyledTextLabel>
            {optionsUpdated && (
              <Controller
                name="code"
                control={control}
                rules={{
                  required: 'field.required',
                }}
                render={({ field: { onChange } }) => (
                  <StyledSelect
                    onChange={selectedValue => {
                      if (!selectedValue) {
                        return
                      }

                      const selectedValueString = selectedValue.toString()

                      const requireProducts =
                        optionsFormated.find(
                          option => option.value === selectedValueString,
                        )?.requireProducts || false

                      if (!requireProducts) {
                        setValue('productLabel', [])
                        setValue('productReference', [])
                        setValue('productWorksiteDeliveryDate', '')
                      } else {
                        setValue(
                          'productWorksiteDeliveryDate',
                          new Date().toISOString(),
                        )
                      }

                      setDisplayedProducts(requireProducts)
                      onChange(selectedValue)
                    }}
                    id="code"
                    items={optionsFormated}
                    placeholder={
                      t('order-form-step1.placeholderPackageType') || ''
                    }
                    errorMessage={t(noticeErrorMessage) || ''}
                  />
                )}
              />
            )}
          </Col>
        </Row>
        <Row margin="bottom-xs">
          <Col sm="12">
            <Controller
              name="quantity"
              defaultValue={DEFAULT_VALUE_ONE}
              control={control}
              rules={{
                required: 'field.required',
                min: {
                  value: 1,
                  message: 'number_field.error.must_be_positive',
                },
                pattern: {
                  value: /^[0-9]*[1-9]+$|^[1-9]+[0-9]*$/,
                  message: 'number_field.error.must_be_whole_number',
                },
              }}
              render={({ field: { name, value, onBlur, onChange } }) => (
                <TextField
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  type="number"
                  label={t('order-form-step1.labelQuantity') || ''}
                  labelSize="sm"
                  labelColor="grey"
                  min={DEFAULT_VALUE_ONE}
                  max="1000"
                  placeholder={t('order-form-step1.placeholderQuantity') || ''}
                  errorMessage={t(quantityErrorMessage) || ''}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert
              status="info"
              htmlText={t('order-form-step1.alertCheckin') || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Controller
              name="package-checked"
              control={control}
              defaultValue={false}
              rules={{ required: true }}
              render={({ field: { value, onBlur, onChange } }) => (
                <Checkbox
                  onChange={onChange}
                  onBlur={onBlur}
                  noBold
                  item={{
                    id: 'package-checked',
                    name: 'package-checked',
                    value: t('order-form-step1.checkboxRadioCheckin') || '',
                    checked: value,
                  }}
                  errorMessage={
                    errors['package-checked'] && `${t('field.required')}`
                  }
                />
              )}
            />
          </Col>
        </Row>
        {displayedProducts && (
          <>
            <Row>
              <Col>
                <Title headingLevel="3">
                  {t('order-form-step1.titleProducts')}
                </Title>
              </Col>
            </Row>
            <Row>
              <Col margin="vertical-xs">
                {t('order-form-step1.descriptionProducts') || ''}
              </Col>
            </Row>
            {productsIds.map((id, index) => (
              <div key={id?.toString()}>
                <Product
                  productNumber={index + 1}
                  id={id?.toString()}
                  t={t}
                  control={control}
                  errors={errors}
                  deleteCallback={() => {
                    submitTrigger()

                    if (orderFormStep1ValuesStringified) {
                      const orderFormInfoStep1 = JSON.parse(
                        orderFormStep1ValuesStringified || '',
                      )

                      // Remove product fields value (react hook form)
                      type KeysType = keyof FormData

                      // Set localStorage info on fields
                      const keys = Object.keys(
                        orderFormInfoStep1,
                      ) as Array<KeysType>

                      keys.map((key: KeysType) => {
                        const orderValue = orderFormInfoStep1[key]

                        if (typeof orderValue !== 'object') {
                          return ''
                        }

                        return setValue(
                          key,
                          orderValue.filter(
                            (code: string, i: number) => i !== index,
                          ),
                        )
                      })
                    }

                    // remove product on local state
                    setProductsIds(current =>
                      current.filter(product => product !== id),
                    )

                    submitTrigger()
                  }}
                />
              </div>
            ))}
            <Row margin="top-xxs">
              <Col>
                <StyledHr />
              </Col>
            </Row>
            <Row margin="top-xxs">
              <StyledLink
                onClick={() => {
                  // Add a new product
                  const lastProductId = productsIds.at(-1)
                  setProductsIds(current => [
                    ...current,
                    (lastProductId || 0) + 1,
                  ])
                  submitTrigger()
                }}
              >
                <Icon
                  name="plus-circle-fill"
                  size="md"
                  color="primary"
                  margin="right-xxs"
                />
                <Text color="primary">
                  {t('order-form-step1.labelButtonAddNewProduct') || ''}
                </Text>
              </StyledLink>
            </Row>
            <Row>
              <Col>
                <Alert
                  status="info"
                  htmlText={
                    t('order-form-step1.descriptionAlertAvailabityDate') || ''
                  }
                />
              </Col>
            </Row>
            <Row margin="bottom-md">
              <Label htmlFor="availibityDate-product">
                {t('order-form-step1.labelAvailabityDate') || ''}
              </Label>
              <Controller
                name="productWorksiteDeliveryDate"
                defaultValue={undefined}
                control={control}
                rules={{
                  required: 'field.required',
                }}
                render={({ field: { name, value, onBlur, onChange } }) => (
                  <StyledDatePicker
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    selected={value ? new Date(value) : undefined}
                    minDate={new Date()}
                    title={t('order-form-step1.titleAlertAvailabityDate') || ''}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="jj/mm/aaaa"
                    isModal
                    errorMessage={
                      errors.productWorksiteDeliveryDate
                        ? t(
                            `${
                              errors?.productWorksiteDeliveryDate?.message || ''
                            }`,
                          ) || undefined
                        : undefined
                    }
                    onCalendarClose={submitTrigger}
                  />
                )}
              />
            </Row>
          </>
        )}
      </Card>
    </form>
  )
}

export default FormPartPackage
