/* eslint-disable no-param-reassign */
import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
import { checkSession } from '@/store/auth/authSlice'
import { WELDOM_SEARCH_URL } from '@/constants/form'

export const ORDER_STEP1 = 'orderFormStep1'
export const ORDER_STEP2 = 'orderFormStep2'
export const ORDER_STEP3 = 'orderFormStep3'
const ERROR_ALREADY_EXIST = 'alreadyExist'
const VENDOR_ORDER_ID = 'vendorInfo.orderId'

type OrderState = {
  hasFormError: boolean | null
  orderId: string | null
  isSendOrderFailed: boolean
  displayAlreadyUseError: boolean
  currentOrder: {
    id: string
    checkoutCompletedAt: string
    customerInfo: CustomerInfo
    craftmanServices: CraftmanServices[]
  }
  storeList: StoreList[]
  packageListStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
  packageList: PackageList[]
  packageListError?: string
}

type CustomerInfo = {
  mobilePhoneNumber: string
  email: string
}

type StoreList = {
  name: string
  code: string
}

type CraftmanServices = {
  quantity: number
  code: string
  purchasedProducts: PurchasedProducts[]
}

type PurchasedProducts = {
  worksiteDeliveryDate: string
}

type SendOrderError = {
  response: {
    status: number
    data: {
      errors: {
        [VENDOR_ORDER_ID]: string
      }
    }
  }
}

type PackageList = {
  name: string
  code: string
  requireProducts: boolean
}

export const sendOrder = createAsyncThunk(
  'order/set',
  async (data, thunkAPI) => {
    try {
      const {
        code,
        quantity,
        productLabel,
        productReference,
        productWorksiteDeliveryDate,
      } = JSON.parse(localStorage.getItem(ORDER_STEP1) || '')
      const { civility, firstName, lastName, mobilePhoneNumber, email } =
        JSON.parse(localStorage.getItem(ORDER_STEP2) || '')
      const { orderId, orderChannel, orderStoreCode, checkoutCompletedAt } =
        JSON.parse(localStorage.getItem(ORDER_STEP3) || '')
      const mobilePhone =
        mobilePhoneNumber.charAt(0) === '0'
          ? mobilePhoneNumber.substring(1)
          : mobilePhoneNumber
      const generateLink = (label: string, reference: string) =>
        `${WELDOM_SEARCH_URL}${encodeURIComponent(label)}%20${reference}`

      const purchasedProducts = productLabel.map(
        (label: string, index: number) => ({
          code: productReference[index],
          quantity: 1,
          manualUrls: [],
          vendorProductSheetUrl: generateLink(label, productReference[index]),
          worksiteDeliveryDate: productWorksiteDeliveryDate,
          label,
        }),
      )

      const localData = {
        vendorInfo: {
          orderId,
          orderChannel,
          orderStoreCode,
        },
        craftmanServices: [
          {
            code,
            quantity: Number(quantity),
            subServices: [],
            purchasedProducts,
          },
        ],
        customerInfo: {
          civility,
          firstName,
          lastName,
          mobilePhoneNumber: `+33${mobilePhone as string}`,
          email,
        },
        checkoutCompletedAt,
      }

      await thunkAPI.dispatch(checkSession())

      const response = await axios.post('partner-contract/orders', localData)

      return response.data
    } catch (error) {
      const typedError = error as SendOrderError
      const errorData = typedError.response.data.errors
      if (errorData[VENDOR_ORDER_ID] === 'This value is already used') {
        throw new Error(ERROR_ALREADY_EXIST)
      }
      throw new Error()
    }
  },
)

export const getStore = createAsyncThunk(
  'store/get',
  async (data, thunkAPI) => {
    try {
      await thunkAPI.dispatch(checkSession())
      const response = await axios.get('shop-api/stores')

      return response.data
    } catch (error) {
      console.error({ error })
      throw new Error()
    }
  },
)

export const getFeatureFlags = createAsyncThunk(
  'ff/get',
  async (data, thunkAPI) => {
    try {
      await thunkAPI.dispatch(checkSession())
      const response = await axios.get('front-api/feature-flags/order_desktop')

      return response.data
    } catch (error) {
      console.error({ error })
      throw new Error()
    }
  },
)

export const fetchPackages = createAsyncThunk(
  'orders/fetchPackages',
  async (data, thunkAPI) => {
    try {
      await thunkAPI.dispatch(checkSession())
      const response = await axios.get('shop-api/partner/products')

      return response.data
    } catch (error) {
      console.error({ error })
      throw new Error()
    }
  },
)

const slice = createSlice({
  name: 'order',
  initialState: {
    hasFormError: '',
    orderId: '',
    isSendOrderFailed: false,
    displayAlreadyUseError: false,
    storeList: [],
    currentOrder: null,
    packageList: [],
  } as unknown as OrderState,
  reducers: {
    setFormError: (state, { payload }) => {
      state.hasFormError = payload
      state.orderId = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(sendOrder.rejected, (state, { error }) => {
      state.isSendOrderFailed = true

      if (error.message === ERROR_ALREADY_EXIST) {
        state.displayAlreadyUseError = true
      } else {
        state.displayAlreadyUseError = false
      }
    })
    builder.addCase(sendOrder.pending, state => {
      state.isSendOrderFailed = false
    })
    builder.addCase(sendOrder.fulfilled, (state, { payload }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.currentOrder = payload || null
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.orderId = payload?.id || null
      state.isSendOrderFailed = false
      localStorage.removeItem(ORDER_STEP1)
      localStorage.removeItem(ORDER_STEP2)
      localStorage.removeItem(ORDER_STEP3)
    })

    builder.addCase(getStore.fulfilled, (state, { payload }) => {
      state.storeList = payload
    })

    builder
      .addCase(fetchPackages.pending, state => {
        state.packageListStatus = 'loading'
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        state.packageListStatus = 'succeeded'
        state.packageList = action.payload
      })
      .addCase(fetchPackages.rejected, (state, action) => {
        state.packageListStatus = 'failed'
        state.packageListError = action.error.message
      })
  },
})

export const { setFormError } = slice.actions

export default slice.reducer

export const selectHasFormError = (state: RootState) => state.order.hasFormError
export const selectIsSendOrderFailed = (state: RootState) =>
  state.order.isSendOrderFailed
// confirmer son functionnement avec le ticket d'envoie des informations du formulaire
export const selectGetOrderId = (state: RootState) =>
  state.order.orderId || null
export const getStoreList = (state: RootState) => state.order.storeList
export const getCurrentOrderProductQtt = (state: RootState) =>
  state.order.currentOrder?.craftmanServices[0]?.quantity
export const getCurrentOrderProductCode = (state: RootState) =>
  state.order.currentOrder?.craftmanServices[0]?.code
export const selectPackageList = (state: RootState) => state.order.packageList
export const currentOrderPackageName = (state: RootState) => {
  const code = getCurrentOrderProductCode(state) || null
  const packageList = selectPackageList(state)
  const sameCode = packageList.filter(pack => pack.code === code)
  return sameCode[0]?.name
}
export const getCurrentOrderClientPhoneNumber = (state: RootState) =>
  state.order.currentOrder?.customerInfo?.mobilePhoneNumber
export const getCurrentOrderClientEmail = (state: RootState) =>
  state.order.currentOrder?.customerInfo?.email
export const getCurrentOrderPurchaseDate = (state: RootState) =>
  state.order.currentOrder?.checkoutCompletedAt
export const getCurrentOrderWorksiteDeliveryDate = (state: RootState) =>
  state.order.currentOrder?.craftmanServices[0]?.purchasedProducts[0]
    ?.worksiteDeliveryDate
export const selectDisplayAlreadyUseError = (state: RootState) =>
  state.order.displayAlreadyUseError
