import styled from 'styled-components'
import { TextField } from '@quotatisgroup/uikit-public.ui.text-field'
import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Text } from '@quotatisgroup/uikit-public.ui.text'
import { Select } from '@quotatisgroup/uikit-public.ui.select'
import { DatePicker } from '@quotatisgroup/uikit-public.ui.date-picker'
import { GREY_COLOR, GREY_COLOR_LIGHT } from '@/constants/colors'

export const Label = styled.label`
  color: ${GREY_COLOR};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
`

export const StyledSelect = styled(Select)`
  margin-top: 8px !important;
`

export const StyledTextLabel = styled(Text)`
  font-weight: 500;
`

export const StyledTextField = styled(TextField)`
  margin-top: 0;
`

export const StyledHr = styled.hr`
  margin-top: 24px;
  border: 0;
  border-top: 0.5px solid ${GREY_COLOR_LIGHT};
`

export const StyledLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
`

export const LabelWithIcon = styled(Label)`
  display: flex;
  align-items: center;
`

export const DeleteButton = styled.div`
  cursor: pointer;
`

export const StyledColDetete = styled(Col)`
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`
