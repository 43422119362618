import styled from 'styled-components'
import { Container } from '@quotatisgroup/uikit-public.ui.container'
import { Col } from '@quotatisgroup/uikit-public.ui.col'

export const StyledContainer = styled(Container)`
  height: calc(100vh - 225px);
`
export const StyledCol = styled(Col)`
  height: 100%;
  padding-top: 40px;
`
