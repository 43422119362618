import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Text } from '@quotatisgroup/uikit-public.ui.text'
import { Link } from '@quotatisgroup/uikit-public.ui.link'

import { logout, selectUserEmail } from '@/store/auth/authSlice'
import {
  StyledCol,
  StyledDesktopAccount,
  StyledPhoneAccount,
  StyledText,
  StyledHr,
  StyledLink,
} from './HeaderStyle'
import { useAppDispatch } from '@/store'

type MenuAccountProps = {
  isMenuOpen: boolean
}

function MenuAccount({ isMenuOpen }: MenuAccountProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const email = useSelector(selectUserEmail)

  const logoutFunc = useCallback(
    async () => dispatch(logout()).catch(error => console.error(error)),
    [],
  )

  return (
    <>
      <StyledCol md="2" offset="8" mdPadding="right-sm">
        <StyledDesktopAccount
          md={isMenuOpen === true ? '12' : 'hide'}
          sm="hide"
        >
          <Col>
            <StyledText caption color="grey" margin="null">
              {t('connection.header.account')}
            </StyledText>
          </Col>
          <Col>
            <Text caption margin="vertical-xs">
              {t('connection.header.account.email', { email })}
            </Text>
          </Col>
          <StyledHr />
          <Col>
            <StyledLink
              href="/"
              id="log_out"
              color="black"
              onClick={logoutFunc}
            >
              {t('connection.header.log_out')}
            </StyledLink>
          </Col>
        </StyledDesktopAccount>
      </StyledCol>
      <StyledPhoneAccount md="hide" sm={isMenuOpen === true ? '12' : 'hide'}>
        <Col>
          <StyledText caption color="grey" margin="null">
            {t('connection.header.account')}
          </StyledText>
        </Col>
        <Col>
          <Text caption margin="vertical-xs">
            {t('connection.header.account.email', { email })}
          </Text>
        </Col>
        <StyledHr />
        <Col>
          <Link href="/" id="log_out" bold color="black" onClick={logoutFunc}>
            {t('connection.header.log_out')}
          </Link>
        </Col>
      </StyledPhoneAccount>
    </>
  )
}

export default MenuAccount
