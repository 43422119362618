import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card } from '@quotatisgroup/uikit-public.ui.card'
import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Row } from '@quotatisgroup/uikit-public.ui.row'
import { Icon } from '@quotatisgroup/uikit-public.ui.icon'
import {
  selectIsSendOrderFailed,
  selectGetOrderId,
} from '@/store/order/orderSlice'

function FormPartConfirmation() {
  const requestFailed = useSelector(selectIsSendOrderFailed)
  const commandId = useSelector(selectGetOrderId)
  const navigate = useNavigate()

  useEffect(() => {
    if (requestFailed && !commandId) {
      navigate('/error-form')
    }

    if (!requestFailed && commandId) {
      navigate('/confirmation-form')
    }
  }, [requestFailed, navigate, commandId])

  return (
    <Card format="simple" margin="vertical-sm">
      <Row>
        <Col sm="12" md="12" align="center">
          <Icon name="spinner" size="xl" color="primary" margin="xl" />
        </Col>
      </Row>
    </Card>
  )
}

export default FormPartConfirmation
