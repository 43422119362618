import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Card } from '@quotatisgroup/uikit-public.ui.card'
import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Row } from '@quotatisgroup/uikit-public.ui.row'
import { Text } from '@quotatisgroup/uikit-public.ui.text'
import { Title } from '@quotatisgroup/uikit-public.ui.title'
import { Tooltip } from '@quotatisgroup/uikit-public.ui.tooltip'
import { Icon } from '@quotatisgroup/uikit-public.ui.icon'
import useMount from '@/hooks/useMount'
import { useAppDispatch } from '@/store'

import {
  setFormError,
  selectHasFormError,
  getStore,
  getStoreList,
  ORDER_STEP3,
} from '@/store/order/orderSlice'
import {
  LabelWithIcon,
  StyledTextField,
  StyledSelectField,
  StyledDateField,
} from './FormPartSellerStyle'

type FormData = {
  orderId: string
  orderChannel: string
  orderStoreCode: string
  checkoutCompletedAt: Date
}

function FormPartSeller() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const today = new Date()
  const {
    formState: { errors },
    control,
    setValue,
    handleSubmit,
    watch,
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })
  const hasFormErrorStored = useSelector(selectHasFormError)
  const storeList = useSelector(getStoreList)
  const [hasSubmited, setHasSubmited] = useState(false)
  const [storeOptions, setStoreOptions] = useState([{ label: '', value: '' }])
  const [orderChannelOptions, setPartnerChannelOptions] = useState([
    { label: '', value: '', selected: false },
  ])
  const localStep3 = localStorage.getItem(ORDER_STEP3)
  const orderFormInfoStep3 = localStep3 ? JSON.parse(localStep3 || '') : null

  const hasFormError = (Object.keys(errors) || []).length > 0

  const [dateIdToolTipToBig, setDateIdToolTipToBig] = useState(false)
  const screenWidth = document.documentElement.scrollWidth

  const onSubmit: SubmitHandler<FormData> = data => {
    setHasSubmited(true)
    localStorage.setItem(ORDER_STEP3, JSON.stringify(data))
  }

  // Set select inputs with local storage
  useEffect(() => {
    // orderStoreCode Part
    const localStoreCode = orderFormInfoStep3?.orderStoreCode
    const options = (storeList || []).map(store => ({
      label: store?.name,
      value: store?.code,
      selected: localStoreCode === store?.code,
    })) as { label: string; value: string }[]

    if (options && options.length > 0) {
      setStoreOptions(options)
      setValue('orderStoreCode', localStoreCode)
    } else {
      setStoreOptions([{ label: '', value: '' }])
    }

    // orderChannel Part
    const localPartnerChannel = orderFormInfoStep3?.orderChannel

    setPartnerChannelOptions([
      {
        value: 'store',
        label: 'Commande magasin',
        selected: localPartnerChannel === 'store',
      },
      {
        value: 'web',
        label: 'Commande web',
        selected: localPartnerChannel === 'web',
      },
    ])
    setValue('orderChannel', localPartnerChannel)
  }, [storeList, setStoreOptions, setPartnerChannelOptions])

  // Set errors to null at fist time
  useEffect(() => {
    dispatch(setFormError(null))
  }, [dispatch])

  // Dispatch Errors Form
  useEffect(() => {
    if (!hasSubmited || hasFormErrorStored === hasFormError) {
      return
    }
    dispatch(setFormError(hasFormError))
  }, [hasFormErrorStored, hasFormError, dispatch, hasSubmited])

  // Local Storage
  useEffect(() => {
    if (!orderFormInfoStep3) {
      return
    }

    type KeysType = keyof FormData

    // Set localStorage info on fields
    const keys = Object.keys(orderFormInfoStep3) as Array<KeysType>

    // Set localStorage info on fields
    keys.map((key: KeysType) => setValue(key, orderFormInfoStep3[key]))

    setHasSubmited(true)
    handleSubmit(onSubmit)().catch(error => console.error(error))
  }, [setValue, localStep3, handleSubmit])

  useEffect(() => {
    const subscription = watch(value => {
      if (
        value.orderId &&
        value.orderChannel &&
        value.orderStoreCode &&
        value.checkoutCompletedAt
      ) {
        setHasSubmited(true)
        handleSubmit(onSubmit)().catch(error => console.error(error))
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, handleSubmit])

  useMount(() => {
    dispatch(getStore()).catch(error => console.error(error))
  })

  useEffect(() => {
    if (screenWidth < 910) {
      setDateIdToolTipToBig(true)
    }
  }, [setDateIdToolTipToBig])

  return (
    <Card format="simple" margin="vertical-sm">
      <Row>
        <Col sm="12">
          <Title headingLevel="3">{t('order-form-step3.title')}</Title>
        </Col>
      </Row>
      <Row margin="bottom-md">
        <Col sm="12">{t('order-form-step3.description')}</Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row margin="bottom-md">
          <Col sm="12">
            <LabelWithIcon htmlFor="orderId">
              {t('order-form-step3.label.orderId') || ''}
              <Tooltip
                trigger={<Icon name="info" size="sm" color="black" />}
                position={dateIdToolTipToBig ? 'bottom' : 'right'}
              >
                <Text>{t('order-form-step3.tooltip.orderId') || ''}</Text>
              </Tooltip>
            </LabelWithIcon>
            <Controller
              name="orderId"
              defaultValue=""
              control={control}
              rules={{
                required: 'field.required',
              }}
              render={({ field: { name, value, onBlur, onChange } }) => (
                <StyledTextField
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  type="text"
                  placeholder={t('order-form-step3.placeholder.orderId') || ''}
                  errorMessage={
                    errors.orderId
                      ? t(`${errors?.orderId?.message || ''}`) || undefined
                      : undefined
                  }
                />
              )}
            />
          </Col>
        </Row>
        <Row margin="bottom-md">
          <Col sm="12">
            {orderChannelOptions && orderChannelOptions[0].label && (
              <>
                <LabelWithIcon htmlFor="orderChannel">
                  {t('order-form-step3.label.salesChannel') || ''}
                  <Tooltip
                    trigger={<Icon name="info" size="sm" color="black" />}
                    position="right"
                  >
                    <Text>
                      {t('order-form-step3.tooltip.salesChannel') || ''}
                    </Text>
                  </Tooltip>
                </LabelWithIcon>
                <Controller
                  name="orderChannel"
                  control={control}
                  rules={{
                    required: 'field.required',
                  }}
                  render={({ field: { onChange } }) => (
                    <StyledSelectField
                      onChange={onChange}
                      id="orderChannel"
                      placeholder={
                        t('order-form-step3.placeholder.salesChannel') || ''
                      }
                      errorMessage={
                        errors.orderChannel
                          ? t(`${errors?.orderChannel?.message || ''}`) ||
                            undefined
                          : undefined
                      }
                      items={orderChannelOptions}
                    />
                  )}
                />
              </>
            )}
          </Col>
        </Row>
        <Row margin="bottom-md">
          <Col sm="12">
            {storeOptions && storeOptions[0].label && (
              <>
                <LabelWithIcon htmlFor="orderStoreCode">
                  {t('order-form-step3.label.saleStore') || ''}
                  <Tooltip
                    trigger={<Icon name="info" size="sm" color="black" />}
                    position="right"
                  >
                    <Text>{t('order-form-step3.tooltip.saleStore') || ''}</Text>
                  </Tooltip>
                </LabelWithIcon>
                <Controller
                  name="orderStoreCode"
                  control={control}
                  rules={{
                    required: 'field.required',
                  }}
                  render={({ field: { onChange } }) => (
                    <StyledSelectField
                      onChange={onChange}
                      id="orderStoreCode"
                      placeholder={
                        t('order-form-step3.placeholder.saleStore') || ''
                      }
                      errorMessage={
                        errors.orderStoreCode
                          ? t(`${errors?.orderStoreCode?.message || ''}`) ||
                            undefined
                          : undefined
                      }
                      items={storeOptions}
                    />
                  )}
                />
              </>
            )}
          </Col>
        </Row>
        <Row margin={dateIdToolTipToBig ? 'bottom-lg' : 'bottom-md'}>
          <Col sm="12">
            <LabelWithIcon htmlFor="checkoutCompletedAt">
              {t('order-form-step3.label.orderDate') || ''}
              <Tooltip
                trigger={<Icon name="info" size="sm" color="black" />}
                position={dateIdToolTipToBig ? 'bottom' : 'right'}
              >
                <Text>{t('order-form-step3.tooltip.orderDate') || ''}</Text>
              </Tooltip>
            </LabelWithIcon>
            <Controller
              name="checkoutCompletedAt"
              defaultValue={today}
              control={control}
              rules={{
                required: 'field.required',
              }}
              render={({ field: { name, value, onBlur, onChange } }) => (
                <StyledDateField
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  selected={value ? new Date(value) : today}
                  minDate={null}
                  timeInputLabel={
                    t('order-form-step3.timeInputLabel.orderDate') || ''
                  }
                  showTimeInput
                  dateFormat="dd/MM/yyyy kk:mm"
                  maxDate={today}
                  placeholderText={
                    t('order-form-step3.placeholder.orderDate') || ''
                  }
                  isModal
                  errorMessage={
                    errors.checkoutCompletedAt
                      ? t(`${errors?.checkoutCompletedAt?.message || ''}`) ||
                        undefined
                      : undefined
                  }
                />
              )}
            />
          </Col>
        </Row>
      </form>
    </Card>
  )
}

export default FormPartSeller
