import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { Alert } from '@quotatisgroup/uikit-public.ui.alert'
import { Card } from '@quotatisgroup/uikit-public.ui.card'
import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Row } from '@quotatisgroup/uikit-public.ui.row'
import { Text } from '@quotatisgroup/uikit-public.ui.text'
import { Title } from '@quotatisgroup/uikit-public.ui.title'
import { TextField } from '@quotatisgroup/uikit-public.ui.text-field'
import { Tooltip } from '@quotatisgroup/uikit-public.ui.tooltip'
import { Icon } from '@quotatisgroup/uikit-public.ui.icon'
import { emailValidate } from '@/utils/validation'
import { GENDER_MRS, GENDER_MR } from '@/constants/form'
import {
  setFormError,
  selectHasFormError,
  ORDER_STEP2,
} from '@/store/order/orderSlice'
import RadioComponent from '@/components/Radio/Radio'

import Label from './Label'
import {
  LabelWithIcon,
  StyledPhone,
  StyledTextField,
  StyledTooltip,
} from './FormPartClientStyle'

type FormData = {
  civility: string
  email: string
  emailConfirmation: string
  firstName: string
  lastName: string
  mobilePhoneConfirmation: string
  mobilePhoneNumber: string
}

function FormPartClient() {
  const { t } = useTranslation()
  const {
    formState: { errors },
    control,
    getValues,
    setValue,
    handleSubmit,
    watch,
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const dispatch = useDispatch()
  const hasFormErrorStored = useSelector(selectHasFormError)
  const [hasSubmited, setHasSubmited] = useState(false)
  const [defaultValue, setDefaultValue] = useState('mr')
  const orderFormStep2ValuesStringified = localStorage.getItem(ORDER_STEP2)

  const [mobileToolTipToBig, setMobileToolTipToBig] = useState(false)
  const [mobileConfToolTipToBig, setMobileConfToolTipToBig] = useState(false)
  const [mailConfToolTipToBig, setMailConfToolTipToBig] = useState(false)
  const screenWidth = document.documentElement.scrollWidth

  const onSubmit: SubmitHandler<FormData> = data => {
    setHasSubmited(true)
    localStorage.setItem(ORDER_STEP2, JSON.stringify(data))
  }

  const hasFormError = (Object.keys(errors) || []).length > 0

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Set errors to null at fist time
  useEffect(() => {
    dispatch(setFormError(null))
  }, [dispatch])

  // Dispatch Errors Form
  useEffect(() => {
    if (!hasSubmited || hasFormErrorStored === hasFormError) {
      return
    }
    dispatch(setFormError(hasFormError))
  }, [hasFormErrorStored, hasFormError, dispatch, hasSubmited])

  // Local Storage
  useEffect(() => {
    if (!orderFormStep2ValuesStringified) {
      return
    }

    // Get localStorage info
    const orderFormInfoStep2: FormData = JSON.parse(
      orderFormStep2ValuesStringified || '',
    )

    setDefaultValue(orderFormInfoStep2.civility)

    type KeysType = keyof FormData

    // Set localStorage info on fields
    const keys = Object.keys(orderFormInfoStep2) as Array<KeysType>
    keys.map(key => setValue(key, orderFormInfoStep2[key]))

    setHasSubmited(true)
    handleSubmit(onSubmit)().catch(error => console.error(error))
  }, [setValue, orderFormStep2ValuesStringified, handleSubmit])

  useEffect(() => {
    const subscription = watch(value => {
      if (
        value.civility &&
        value.emailConfirmation &&
        value.firstName &&
        value.lastName &&
        value.mobilePhoneConfirmation
      ) {
        setHasSubmited(true)
        handleSubmit(onSubmit)().catch(error => console.error(error))
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, handleSubmit])

  useEffect(() => {
    if (screenWidth <= 800) {
      setMobileToolTipToBig(true)
    }
    if (screenWidth <= 955) {
      setMobileConfToolTipToBig(true)
    }
    if (screenWidth <= 835) {
      setMailConfToolTipToBig(true)
    }
  }, [
    setMobileToolTipToBig,
    setMobileConfToolTipToBig,
    setMailConfToolTipToBig,
  ])

  return (
    <Card format="simple" margin="vertical-sm">
      <Row>
        <Col>
          <Title headingLevel="3">{t('order-form-step2.title')}</Title>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <Text>{t('order-form-step2.description')}</Text>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row margin="bottom-xs">
          <Col sm="12">
            <Label>{t('order-form-step2.label.civility')}</Label>
            <Row margin="top-xs">
              <Controller
                name="civility"
                control={control}
                rules={{
                  required: 'field.required',
                }}
                defaultValue={defaultValue}
                render={({ field }) => {
                  const { value, name, onBlur, onChange } = field

                  return (
                    <>
                      <RadioComponent
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        id="civility-mr"
                        value="mr"
                        label={t('order-form-step2.gender.mr')}
                        defaultChecked={value === GENDER_MR}
                      />
                      <RadioComponent
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        id="civility-mrs"
                        value="mrs"
                        label={t('order-form-step2.gender.mrs')}
                        defaultChecked={value === GENDER_MRS}
                      />
                    </>
                  )
                }}
              />
            </Row>
            {errors.civility && (
              <span style={{ color: 'red' }}>
                {t(`${errors.civility.message || ''}`)}
              </span>
            )}
          </Col>
        </Row>
        <Row margin="bottom-xs">
          <Col sm="12">
            <Controller
              name="firstName"
              defaultValue=""
              control={control}
              rules={{
                required: 'field.required',
              }}
              render={({ field: { name, value, onBlur, onChange } }) => (
                <TextField
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  label={t('order-form-step2.label.firstname') || ''}
                  labelSize="sm"
                  labelColor="grey"
                  type="text"
                  placeholder={
                    t('order-form-step2.placeholder.firstname') || ''
                  }
                  errorMessage={
                    errors.firstName
                      ? t(`${errors.firstName.message || ''}`) || undefined
                      : undefined
                  }
                />
              )}
            />
          </Col>
        </Row>
        <Row margin="bottom-xs">
          <Col sm="12">
            <Controller
              name="lastName"
              defaultValue=""
              control={control}
              rules={{
                required: 'field.required',
              }}
              render={({ field: { name, value, onBlur, onChange } }) => (
                <TextField
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  label={t('order-form-step2.label.lastname') || ''}
                  labelSize="sm"
                  labelColor="grey"
                  type="text"
                  placeholder={t('order-form-step2.placeholder.lastname') || ''}
                  errorMessage={
                    errors.lastName
                      ? t(`${errors.lastName.message || ''}`) || undefined
                      : undefined
                  }
                />
              )}
            />
          </Col>
        </Row>
        <Row margin="bottom-xs">
          <Col>
            <Alert
              status="warning"
              icon="alert-triangle-fill"
              title={t('order-form-step2.alert.title') || ''}
              htmlText={t('order-form-step2.alert.description') || ''}
            />
          </Col>
        </Row>
        <Row margin="bottom-sm">
          <Col sm="12">
            <LabelWithIcon htmlFor="orderId">
              {t('order-form-step2.label.phone') || undefined}
              <StyledTooltip
                trigger={<Icon name="info" size="sm" color="black" />}
                position={mobileToolTipToBig ? 'bottom' : 'right'}
              >
                <Text>{t('order-form-step2.tooltip.phone') || ''}</Text>
              </StyledTooltip>
            </LabelWithIcon>
            <Controller
              name="mobilePhoneNumber"
              defaultValue=""
              control={control}
              rules={{
                required: 'field.required',
                pattern: {
                  value: /^^0[67]{1}[0-9]{8}$/,
                  message: 'field.phone.error.format',
                },
              }}
              render={({ field: { name, value, onBlur, onChange } }) => (
                <StyledPhone
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder={t('order-form-step2.placeholder.phone') || ''}
                  errorMessage={
                    errors.mobilePhoneNumber
                      ? t(`${errors.mobilePhoneNumber.message || ''}`) ||
                        undefined
                      : undefined
                  }
                />
              )}
            />
          </Col>
        </Row>
        <Row margin="bottom-sm">
          <Col sm="12">
            <LabelWithIcon htmlFor="orderId">
              {t('order-form-step2.label.phone.confirmation') || undefined}
              <Tooltip
                trigger={<Icon name="info" size="sm" color="black" />}
                position={mobileConfToolTipToBig ? 'bottom' : 'right'}
              >
                <Text>
                  {t('order-form-step2.tooltip.phone.confirmation') || ''}
                </Text>
              </Tooltip>
            </LabelWithIcon>
            <Controller
              name="mobilePhoneConfirmation"
              defaultValue=""
              control={control}
              rules={{
                required: 'field.required',
                validate: value =>
                  value === getValues('mobilePhoneNumber') ||
                  'field.error.same',
              }}
              render={({ field: { name, value, onBlur, onChange } }) => (
                <StyledPhone
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder={
                    t('order-form-step2.placeholder.phone.confirm') || ''
                  }
                  errorMessage={
                    errors.mobilePhoneConfirmation
                      ? t(`${errors.mobilePhoneConfirmation.message || ''}`) ||
                        undefined
                      : undefined
                  }
                />
              )}
            />
          </Col>
        </Row>
        <Row margin="bottom-sm">
          <Col sm="12">
            <LabelWithIcon htmlFor="orderId">
              {t('order-form-step2.label.email') || undefined}
              <Tooltip
                trigger={<Icon name="info" size="sm" color="black" />}
                position="right"
              >
                <Text>{t('order-form-step2.tooltip.email') || ''}</Text>
              </Tooltip>
            </LabelWithIcon>
            <Controller
              name="email"
              defaultValue=""
              control={control}
              rules={{
                required: 'field.required',
                pattern: {
                  value: emailValidate,
                  message: 'field.email.error.format',
                },
              }}
              render={({ field: { name, value, onBlur, onChange } }) => (
                <StyledTextField
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  type="text"
                  placeholder={t('order-form-step2.placeholder.email') || ''}
                  errorMessage={
                    errors.email
                      ? t(`${errors.email.message || ''}`) || undefined
                      : undefined
                  }
                />
              )}
            />
          </Col>
        </Row>
        <Row margin={mailConfToolTipToBig ? 'bottom-xl' : 'bottom-md'}>
          <Col sm="12">
            <LabelWithIcon htmlFor="orderId">
              {t('order-form-step2.label.email.confirmation') || undefined}
              <Tooltip
                trigger={<Icon name="info" size="sm" color="black" />}
                position={mailConfToolTipToBig ? 'bottom' : 'right'}
              >
                <Text>
                  {t('order-form-step2.tooltip.email.confirmation') || ''}
                </Text>
              </Tooltip>
            </LabelWithIcon>
            <Controller
              name="emailConfirmation"
              defaultValue=""
              control={control}
              rules={{
                required: 'field.required',
                pattern: {
                  value: emailValidate,
                  message: 'field.email.error.format',
                },
                validate: value =>
                  value === getValues('email') || 'field.error.same',
              }}
              render={({ field: { name, value, onBlur, onChange } }) => (
                <StyledTextField
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  type="text"
                  placeholder={
                    t('order-form-step2.placeholder.email.confirm') || ''
                  }
                  errorMessage={
                    errors.emailConfirmation
                      ? t(`${errors.emailConfirmation.message || ''}`) ||
                        undefined
                      : undefined
                  }
                />
              )}
            />
          </Col>
        </Row>
      </form>
    </Card>
  )
}

export default FormPartClient
