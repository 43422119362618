import styled from 'styled-components'
import { TextField } from '@quotatisgroup/uikit-public.ui.text-field'
import { Select } from '@quotatisgroup/uikit-public.ui.select'
import { DatePicker } from '@quotatisgroup/uikit-public.ui.date-picker'
import { GREY_COLOR } from '@/constants/colors'

export const LabelWithIcon = styled.label`
  display: flex;
  align-items: center;
  color: ${GREY_COLOR};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
`

export const StyledTextField = styled(TextField)`
  margin: 0;
`

export const StyledSelectField = styled(Select)`
  margin: 0;
  div.options.open {
    max-height: calc(100% + 54px);
  }
`

export const StyledDateField = styled(DatePicker)`
  margin: 0;
`
