import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Card } from '@quotatisgroup/uikit-public.ui.card'
import { Row } from '@quotatisgroup/uikit-public.ui.row'
import { Icon } from '@quotatisgroup/uikit-public.ui.icon'
import { Title } from '@quotatisgroup/uikit-public.ui.title'
import { Button } from '@quotatisgroup/uikit-public.ui.button'
import { useSelector } from 'react-redux'
import { toast, Id } from 'react-toastify'
import Layout from '@/templates/Layout'
import { selectDisplayAlreadyUseError } from '@/store/order/orderSlice'

const StyledIcon = styled(Icon)`
  width: 92px !important;
`

function ErrorPage() {
  const { t } = useTranslation()
  const displayAlreadyUseError = useSelector(selectDisplayAlreadyUseError)
  const toastId = React.useRef<Id | null>(null)

  useEffect(() => {
    if (displayAlreadyUseError && toastId.current === null) {
      toastId.current = toast.error(t('error.toast_error.orderId_already_used'))
    }
  }, [displayAlreadyUseError])

  return (
    <Layout>
      <Row padding="vertical-md">
        <Col md="8" sm="12" offset="2" padding="bottom-md">
          <Card format="simple" margin="sm">
            <Row align="center">
              <Col md="6" offset="3" sm="12" padding="top-md">
                <StyledIcon name="alert-triangle" color="danger" />
              </Col>
            </Row>
            <Row align="center">
              <Col md="12">
                <Title headingLevel="3">{t('error_page.subtitle')}</Title>
              </Col>
            </Row>
            <Row align="center">
              <Col md="10" sm="12" offset="1">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('error_page.text'),
                  }}
                />
              </Col>
            </Row>
            <Row align="center">
              <Col md="12" margin="top-md">
                <NavLink id="form.link" to="/form">
                  <Button
                    id="error_page.button.try_again"
                    variant="basic"
                    size="md"
                    md-margin="bottom-sm"
                  >
                    {t('error_page.button.try_again') || ''}
                  </Button>
                </NavLink>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default ErrorPage
