import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Card } from '@quotatisgroup/uikit-public.ui.card'
import { Image } from '@quotatisgroup/uikit-public.ui.image'
import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Row } from '@quotatisgroup/uikit-public.ui.row'
import { Button } from '@quotatisgroup/uikit-public.ui.button'
import { Title } from '@quotatisgroup/uikit-public.ui.title'
import { Text } from '@quotatisgroup/uikit-public.ui.text'
import cloudinary from '@/utils/cloudinary'

import Layout from '@/templates/Layout'

function HomePage() {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <Layout>
      <Row padding="top-md">
        <Col md="8" sm="12" offset="2" padding="bottom-md">
          <Card format="simple" margin="sm">
            <Row align="center">
              <Col md="6" offset="3" sm="12" padding="top-md">
                <Image
                  src={cloudinary('/vitrine/illustration/partner_img_home.svg')}
                  size="full"
                  padding="vertical-sm"
                  smPadding="vertical-xs"
                  alt="home illustration"
                />
              </Col>
            </Row>
            <Row align="center">
              <Col md="12">
                <Title headingLevel="3">{t('home.title')}</Title>
              </Col>
            </Row>
            <Row align="center">
              <Col md="10" sm="12" offset="1">
                <Text>{t('home.description')}</Text>
              </Col>
            </Row>
            <Row align="center">
              <Col md="12" margin="top-md">
                <Link
                  id="form.link"
                  to="/form"
                  state={{ from: location?.pathname }}
                >
                  <Button id="home.button" size="md" md-margin="bottom-sm">
                    {t('home.button.text') || ''}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default HomePage
