import React, { useState, useEffect } from 'react'
import { Controller, Control, FieldErrors } from 'react-hook-form'

import { Row } from '@quotatisgroup/uikit-public.ui.row'
import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Text } from '@quotatisgroup/uikit-public.ui.text'
import { Tooltip } from '@quotatisgroup/uikit-public.ui.tooltip'
import { Icon } from '@quotatisgroup/uikit-public.ui.icon'
import { NUMBER_REGEXP } from '@/constants/form'

import FormData from './FormPartPackageTypes'

import {
  LabelWithIcon,
  StyledTextField,
  StyledHr,
  DeleteButton,
  StyledColDetete,
} from './style'

function Product({
  t,
  productNumber,
  id,
  deleteCallback,
  errors,
  control,
}: {
  t: (key: string) => string
  productNumber: number
  id: string
  deleteCallback: (key: string) => void
  errors: FieldErrors<FormData>
  control: Control<FormData>
}) {
  const referenceError = errors?.productReference?.[productNumber - 1]?.message
  const labelError = errors?.productLabel?.[productNumber - 1]?.message
  const [refToolTipToBig, setRefToolTipToBig] = useState(false)
  const screenWidth = document.documentElement.scrollWidth

  useEffect(() => {
    if (screenWidth < 1540) {
      setRefToolTipToBig(true)
    }
  }, [setRefToolTipToBig])

  return (
    <>
      {productNumber > 1 && (
        <Row>
          <Col>
            <StyledHr />
          </Col>
        </Row>
      )}
      <Row smMargin="top-sm">
        <Col md="10">
          <Text bold>
            {t('order-form-step1.prefixProduct') || ''} {productNumber}
          </Text>
        </Col>
        {productNumber > 1 && (
          <StyledColDetete md="2" align="right">
            <DeleteButton
              aria-hidden="true"
              onClick={() => {
                deleteCallback(id)
              }}
            >
              <Icon name="close-square-fill" size="lg" color="primary" />
            </DeleteButton>
          </StyledColDetete>
        )}
      </Row>
      <Row>
        <Col md="6" sm="12" mdPadding="right-xs" smMargin="bottom-md">
          <LabelWithIcon htmlFor="availibityDate-product">
            {t('order-form-step1.labelNameProduct') || ''}
            <Tooltip
              trigger={<Icon name="info" size="sm" color="black" />}
              position="right"
            >
              <Text>{t('order-form-step1.tooltipNameProduct') || ''}</Text>
            </Tooltip>
          </LabelWithIcon>
          <Controller
            name={`productLabel.${productNumber - 1}`}
            defaultValue=""
            control={control}
            rules={{
              required: 'field.required',
              minLength: {
                value: 2,
                message: 'field.minlength.error',
              },
            }}
            render={({ field: { name, value, onBlur, onChange } }) => (
              <StyledTextField
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                type="text"
                placeholder={t('order-form-step1.placeholderNameProduct') || ''}
                errorMessage={t(labelError || '')}
              />
            )}
          />
        </Col>
        <Col md="6" sm="12" mdPadding="right-xs">
          <LabelWithIcon htmlFor="reference-product">
            {t('order-form-step1.labelProductReference') || ''}
            <Tooltip
              trigger={<Icon name="info" size="sm" color="black" />}
              position={refToolTipToBig ? 'left' : 'right'}
            >
              <Text>{t('order-form-step1.tooltipProductReference') || ''}</Text>
            </Tooltip>
          </LabelWithIcon>
          <Controller
            name={`productReference.${productNumber - 1}`}
            defaultValue=""
            control={control}
            rules={{
              required: 'field.required',
              pattern: {
                value: NUMBER_REGEXP,
                message: 'field.reference.error.format',
              },
              minLength: {
                value: 2,
                message: 'field.minlength.error',
              },
            }}
            render={({ field: { name, value, onBlur, onChange } }) => (
              <StyledTextField
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={
                  t('order-form-step1.placeholderProductReference') || ''
                }
                type="number"
                errorMessage={t(referenceError || '')}
              />
            )}
          />
        </Col>
      </Row>
    </>
  )
}

export default Product
