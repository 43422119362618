const DEFAULT_ENV = 'qa'

function getEnvironmentFromUrl(url: string, service: string, domain: string) {
  if (!url) {
    return 'mocks'
  }

  if (url.includes('localqa1')) {
    return 'localqa1'
  }

  const pattern = new RegExp(`${service}\\.(\\w+)\\.${domain}`)
  const match = url.match(pattern)

  if (match) {
    const envUrl = match[1]
    const env =
      ['qa', 'qa1', 'preprod', 'local'].find(el => el === envUrl) || DEFAULT_ENV

    return env
  }

  return 'prod'
}

export default getEnvironmentFromUrl
