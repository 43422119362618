import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@quotatisgroup/uikit-public.ui.card'
import { Image } from '@quotatisgroup/uikit-public.ui.image'
import { Title } from '@quotatisgroup/uikit-public.ui.title'
import { Text } from '@quotatisgroup/uikit-public.ui.text'

import cloudinary from '@/utils/cloudinary'
import Layout from '@/templates/Layout'
import { StyledContainer, StyledCol } from './TrackingNotFoundPageStyle'

function TrackingNotFoundPage() {
  const { t } = useTranslation()

  return (
    <Layout>
      <StyledContainer>
        <StyledCol md="8" offset="2" sm="12" mdPadding="xs" smPadding="sm" align="center">
          <Card format="simple" padding="top-lg">
            <Image
              src={cloudinary('/vitrine/illustration/tracking_not_found.svg')}
              size="xl"
              margin="bottom-lg"
              smMargin="bottom-sm"
              alt="404 not found"
            />
            <Title headingLevel="5" margin="bottom-sm">
              {t('not_found.title')}
            </Title>
            <Text margin="null">
              {t('not_found.description')}
            </Text>
          </Card>
        </StyledCol>
      </StyledContainer>
    </Layout>
  )
}

export default TrackingNotFoundPage
