import styled from 'styled-components'
import { GREY_COLOR } from '@/constants/colors'

const Label = styled.legend`
  color: ${GREY_COLOR};
  font-size: 12px;
  font-weight: 500;
`

export default Label
