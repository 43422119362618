import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import { Col } from '@quotatisgroup/uikit-public.ui.col'
import { Row } from '@quotatisgroup/uikit-public.ui.row'
import { Text } from '@quotatisgroup/uikit-public.ui.text'
import { StepperForm } from '@quotatisgroup/uikit-public.ui.stepper-form'
import { useLocation } from 'react-router-dom'

import Layout from '@/templates/Layout'
import FormPartPackage from '@/form/FormPartPackage/FormPartPackage'
import FormPartClient from '@/form/FormPartClient/FormPartClient'
import FormPartSeller from '@/form/FormPartSeller/FormPartSeller'
import FormPartConfirmation from '@/form/FormPartConfirmation'
import {
  selectHasFormError,
  sendOrder,
  ORDER_STEP2,
  ORDER_STEP3,
} from '@/store/order/orderSlice'
import { useAppDispatch } from '@/store'
import useMount from '@/hooks/useMount'

function OrderFormPage() {
  const { t } = useTranslation()
  const location = useLocation()
  const hasFormErrorStored = useSelector(selectHasFormError)
  const [stepPosition, setStepPosition] = useState(0)
  const [isLastStep, setIsLastStep] = useState(false)
  const dispatch = useAppDispatch()
  const orderFormStep2 = localStorage.getItem(ORDER_STEP2)
  const orderFormStep3 = localStorage.getItem(ORDER_STEP3)

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <FormPartPackage />
      case 1:
        return <FormPartClient />
      case 2:
        return <FormPartSeller />
      case 3:
        return <FormPartConfirmation />
      default:
        return null
    }
  }

  const getStepPositionForm = useCallback(() => {
    let position = 0

    if (orderFormStep2) {
      position = 1
    }

    if (orderFormStep3) {
      position = 2
    }

    if (location?.state?.from) {
      position = 0
    }

    setStepPosition(position)
  }, [orderFormStep2, orderFormStep3])

  useMount(() => {
    getStepPositionForm()
  })

  useEffect(() => {
    setIsLastStep(stepPosition > 1)
  }, [stepPosition])

  return (
    <>
      <Helmet>
        <title>Form page</title>
      </Helmet>
      <Layout>
        <Row padding="top-lg" margin="sm">
          <Col md="8" sm="12" offset="2">
            <StepperForm
              steps={[
                {
                  stepNumber: 0,
                  label: t('order-form-step1.stepTitle') || '',
                },
                {
                  stepNumber: 1,
                  label: t('order-form-step2.stepTitle') || '',
                },
                {
                  stepNumber: 2,
                  label: t('order-form-step3.stepTitle') || '',
                },
              ]}
              nextAction={() => {
                setStepPosition(stepPosition + 1)
                if (isLastStep) {
                  dispatch(sendOrder()).catch(error => console.error(error))
                }
              }}
              previousAction={() => {
                setStepPosition(stepPosition - 1)
              }}
              getStepContent={getStepContent}
              backBtnLabel={t('back.button') || ''}
              getNextBtnLabel={() =>
                isLastStep ? t('send.button') : t('next.button')
              }
              idNextBtn={`next.button.step${stepPosition + 1}`}
              idPreviousBtn={`previous.button.step${stepPosition + 1}`}
              isNextBtnDisabled={
                hasFormErrorStored === null ? true : hasFormErrorStored
              }
              stepPosition={stepPosition}
              showButtonFinalStep
            />
          </Col>
        </Row>
        <Row align="center" margin="bottom-lg">
          <Col md="8" sm="12" offset="2">
            <Text color="grey" margin="sm">
              {t('form.alert-bottom-button')}
            </Text>
          </Col>
        </Row>
      </Layout>
    </>
  )
}

export default OrderFormPage
